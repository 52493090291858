import clsx from 'clsx';

import type { FC } from 'react';

import { useUnit } from 'effector-react';

import type { SearchFiltersModel } from '@stores/search/factory';
import { setSearchDrawerOpen } from '@stores/search/model';

import { trackException } from '@utils/sentry';

import { Button } from '@components/common/Button/Button';
import { FiltersFormFirstRow } from '@components/common/FiltersForm/FiltersFormFirstRow';
import { FiltersFormSecondRow } from '@components/common/FiltersForm/FiltersFormSecondRow';
import { FiltersFormThirdRow } from '@components/common/FiltersForm/FiltersFormThirdRow';

import styles from './FiltersForm.module.scss';

export type FiltersFormProps = Readonly<{
    searchFiltersModel: SearchFiltersModel;
    titleComponent?: 'h1' | 'h2';
    areActionButtonsVisible?: boolean;
    className?: string;
    isDynamic?: boolean;
}>;

export const FiltersForm: FC<FiltersFormProps> = (props) => {
    const {
        searchFiltersModel: {
            advantagesFilterModel,
            areaRangeFilterModel,
            completionYearFilterModel,
            localityFilterModel,
            priceRangeFilterModel,
            projectFilterModel,
            // eslint-disable-next-line effector/mandatory-scope-binding
            resetFx,
            // eslint-disable-next-line effector/mandatory-scope-binding
            applyFx,
            roomCountFilterModel,
        },
        titleComponent: TitleComponent = 'h2',
        areActionButtonsVisible = true,
        className,
        isDynamic = false,
    } = props;

    const [reset, apply, setOpen] = useUnit([resetFx, applyFx, setSearchDrawerOpen]);

    const handleResetButtonClick = (): void => {
        reset().catch(trackException);
    };

    const handleApplyingFilters = (): void => {
        apply().catch(trackException);
        setOpen(false);
    };

    return (
        <form className={clsx(styles.root, className)}>
            <TitleComponent className={styles.title}>Подбор квартиры</TitleComponent>

            <div className={styles.fieldsRows}>
                <FiltersFormFirstRow
                    className={styles.firstRow}
                    roomsCountFilterModel={roomCountFilterModel}
                    areaFilterModel={areaRangeFilterModel}
                    priceFilterModel={priceRangeFilterModel}
                    handleApplyingFilters={isDynamic ? handleApplyingFilters : undefined}
                />

                <FiltersFormSecondRow
                    className={styles.secondRow}
                    localityFilterModel={localityFilterModel}
                    projectsFilterModel={projectFilterModel}
                    completionYearFilterModel={completionYearFilterModel}
                    handleApplyingFilters={isDynamic ? handleApplyingFilters : undefined}
                />

                <FiltersFormThirdRow
                    className={styles.thirdRow}
                    advantagesFilterModel={advantagesFilterModel}
                    handleApplyingFilters={isDynamic ? handleApplyingFilters : undefined}
                />
            </div>

            {areActionButtonsVisible && (
                <div className={styles.buttons}>
                    {!isDynamic && (
                        <Button
                            variant="darkBlue"
                            className={styles.applyButton}
                            onClick={handleApplyingFilters}
                        >
                            Применить
                        </Button>
                    )}

                    <Button
                        variant="transparentOutline"
                        className={styles.resetButton}
                        onClick={handleResetButtonClick}
                    >
                        Сбросить фильтры
                    </Button>
                </div>
            )}
        </form>
    );
};
