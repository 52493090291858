import { createEffect } from 'effector';

import type { SearchPayload, SearchResponse } from '@api/protocol';

import { makeSearchPayloadFromQuery } from '@utils/search';
import { makeServerSideApiRequest } from '@utils/server-side';

import { searchDomain } from './domain';
import {
    type FetchSearchResultsFxPayload,
    type FetchSearchResultsFxResponse,
    searchPageSize,
} from './lib';

export const fetchSearchResultsFx = createEffect<
    FetchSearchResultsFxPayload,
    FetchSearchResultsFxResponse
>({
    domain: searchDomain,
    handler: async ({ query, headers }) =>
        makeServerSideApiRequest<FetchSearchResultsFxResponse>({
            headers,
            request: async (client): Promise<FetchSearchResultsFxResponse> => {
                const [sortOrder, payload] = makeSearchPayloadFromQuery(query, searchPageSize);

                const { filters, items, page, page_size, total } = await client.get<
                    SearchResponse,
                    SearchPayload
                >('/api/search', payload);

                return {
                    filters,
                    list: items,
                    listPagination: { page, page_size, total },
                    sortOrder,
                };
            },
        }),
});
