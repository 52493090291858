import type { ParsedUrlQuery } from 'querystring';
import { match, P } from 'ts-pattern';

import type { RNEA } from '@feip-internal/fp-ts';
import { F, O, Predicate, R, RA, S } from '@feip-internal/fp-ts';

import type { SearchSortPayload } from '@domains/search';
import { type SearchFiltersPayload, SearchSortMode, SearchSortOrder } from '@domains/search';

import type { MultiselectFilterState } from '@stores/filters/lib';

import type { SearchFilters } from '@api/generated';
import type { SearchPayload } from '@api/protocol';

import {
    parseAdvantagesFilterPayloadFromQuery,
    parseAreaRangeMaxFilterPayload,
    parseAreaRangeMinFilterPayload,
    parseCompletionYearFilterPayloadFromQuery,
    parseLocalityFilterPayloadFromQuery,
    parsePriceRangeMaxFilterPayload,
    parsePriceRangeMinFilterPayload,
    parseProjectFilterPayloadFromQuery,
    parseRoomsNumberFilterFormQuery,
    serializeIntegerRangeFilterToQuery,
    serializeMultiselectFilterToQuery,
} from './filters';

const defaultSearchSortOrder = SearchSortOrder.PriceAsc;

export const serializeSearchFiltersToQuery = (filters: SearchFilters): ParsedUrlQuery => ({
    ...serializeIntegerRangeFilterToQuery('priceRangeMin', 'priceRangeMax', filters.price),
    ...serializeIntegerRangeFilterToQuery('areaRangeMin', 'areaRangeMax', filters.area),
    ...serializeMultiselectFilterToQuery('roomsNumber', filters.roomsNumber),
    ...serializeMultiselectFilterToQuery('locality', filters.locality),
    ...serializeMultiselectFilterToQuery('project', filters.project),
    ...serializeMultiselectFilterToQuery('completionYear', filters.completionYear),
    ...serializeMultiselectFilterToQuery('advantages', filters.advantages),
});

const parseSearchSortOrderFromQuery = (query: ParsedUrlQuery): SearchSortOrder =>
    match([query.sortMode, query.sortDirection])
        .with([SearchSortMode.Price, 'asc'], () => SearchSortOrder.PriceAsc)
        .with([SearchSortMode.Price, 'desc'], () => SearchSortOrder.PriceDesc)
        .with([SearchSortMode.Area, 'asc'], () => SearchSortOrder.AreaAsc)
        .with([SearchSortMode.Area, 'desc'], () => SearchSortOrder.AreaDesc)
        .otherwise(F.constant(defaultSearchSortOrder));

const parseSearchFiltersPayloadFromQuery = (query: ParsedUrlQuery): SearchFiltersPayload => ({
    priceRangeMin: F.pipe(query, parsePriceRangeMinFilterPayload, O.toUndefined),
    priceRangeMax: F.pipe(query, parsePriceRangeMaxFilterPayload, O.toUndefined),
    areaRangeMin: F.pipe(query, parseAreaRangeMinFilterPayload, O.toUndefined),
    areaRangeMax: F.pipe(query, parseAreaRangeMaxFilterPayload, O.toUndefined),
    roomsNumber: F.pipe(query, parseRoomsNumberFilterFormQuery, O.toUndefined),
    advantages: F.pipe(query, parseAdvantagesFilterPayloadFromQuery, O.toUndefined),
    completionYear: F.pipe(query, parseCompletionYearFilterPayloadFromQuery, O.toUndefined),
    locality: F.pipe(query, parseLocalityFilterPayloadFromQuery, O.toUndefined),
    project: F.pipe(query, parseProjectFilterPayloadFromQuery, O.toUndefined),
});

export const serializeSortOrderToQuery = (sortOrder: SearchSortOrder): ParsedUrlQuery => ({
    sortMode: match(sortOrder)
        .with(P.union(SearchSortOrder.AreaAsc, SearchSortOrder.AreaDesc), () => SearchSortMode.Area)
        .with(
            P.union(SearchSortOrder.PriceAsc, SearchSortOrder.PriceDesc),
            () => SearchSortMode.Price,
        )
        .exhaustive(),
    sortDirection: match(sortOrder)
        .with(P.union(SearchSortOrder.AreaAsc, SearchSortOrder.PriceAsc), () => 'asc')
        .with(P.union(SearchSortOrder.AreaDesc, SearchSortOrder.PriceDesc), () => 'desc')
        .exhaustive(),
});

const mapSearchSortOrderToPayload = (sortOrder: SearchSortOrder): SearchSortPayload =>
    match(sortOrder)
        .with(SearchSortOrder.PriceAsc, () => ({
            sortMode: SearchSortMode.Price,
            sortDirection: 'asc' as const,
        }))
        .with(SearchSortOrder.PriceDesc, () => ({
            sortMode: SearchSortMode.Price,
            sortDirection: 'desc' as const,
        }))
        .with(SearchSortOrder.AreaAsc, () => ({
            sortMode: SearchSortMode.Area,
            sortDirection: 'asc' as const,
        }))
        .with(SearchSortOrder.AreaDesc, () => ({
            sortMode: SearchSortMode.Area,
            sortDirection: 'desc' as const,
        }))
        .exhaustive();

export const getSearchSortOptionKey = (sortOrder: SearchSortOrder): string => sortOrder;

export const getSearchSortOptionLabel = (sortOrder: SearchSortOrder): string =>
    match(sortOrder)
        .with(SearchSortOrder.PriceAsc, () => 'По возрастанию цены')
        .with(SearchSortOrder.PriceDesc, () => 'По убыванию цены')
        .with(SearchSortOrder.AreaAsc, () => 'По возрастанию площади')
        .with(SearchSortOrder.AreaDesc, () => 'По убыванию площади')
        .exhaustive();

export const searchSortOptions = F.pipe(
    SearchSortOrder,
    R.foldMap(S.Ord)(RA.getMonoid<SearchSortOrder>())(RA.of),
    F.unsafeCoerce<readonly SearchSortOrder[], RNEA.ReadonlyNonEmptyArray<SearchSortOrder>>,
);

const getSelectedMultiselectFilterValues = <T extends string>(filters: MultiselectFilterState<T>) =>
    F.pipe(
        filters,
        RA.filterMap(({ value, isSelected }) => (isSelected ? O.some(value) : O.none)),
    );

export const makeSearchPayloadFromState = (
    sortOrder: SearchSortOrder,
    filters: SearchFilters,
    searchPageSize: number,
    page: number,
): SearchPayload => ({
    sortMode: match(sortOrder)
        .with(
            P.union(SearchSortOrder.PriceAsc, SearchSortOrder.PriceDesc),
            () => SearchSortMode.Price,
        )
        .with(P.union(SearchSortOrder.AreaAsc, SearchSortOrder.AreaDesc), () => SearchSortMode.Area)
        .exhaustive(),
    sortDirection: match(sortOrder)
        .with(P.union(SearchSortOrder.PriceAsc, SearchSortOrder.AreaAsc), () => 'asc' as const)
        .with(P.union(SearchSortOrder.PriceDesc, SearchSortOrder.AreaDesc), () => 'desc' as const)
        .exhaustive(),
    advantages: getSelectedMultiselectFilterValues(filters.advantages),
    completionYear: getSelectedMultiselectFilterValues(filters.completionYear),
    locality: getSelectedMultiselectFilterValues(filters.locality),
    project: getSelectedMultiselectFilterValues(filters.project),
    roomsNumber: getSelectedMultiselectFilterValues(filters.roomsNumber),
    areaRangeMin: filters.area.selectedMin ?? undefined,
    areaRangeMax: filters.area.selectedMax ?? undefined,
    priceRangeMin: filters.price.selectedMin ?? undefined,
    priceRangeMax: filters.price.selectedMax ?? undefined,
    pageSize: searchPageSize,
    page,
});

export const makeSearchPayloadFromQuery = (
    query: ParsedUrlQuery,
    searchPageSize: number,
): [SearchSortOrder, SearchPayload] => {
    const sortOrder = parseSearchSortOrderFromQuery(query);
    return [
        sortOrder,
        {
            ...mapSearchSortOrderToPayload(sortOrder),
            ...parseSearchFiltersPayloadFromQuery(query),
            pageSize: searchPageSize,
            page: F.pipe(
                query.page,
                O.fromPredicate(S.isString),
                O.filter(Predicate.not(S.isEmpty)),
                O.map(Number),
                O.filter((page) => Number.isSafeInteger(page) && page >= 1),
                O.getOrElse(F.constant(1)),
            ),
        },
    ];
};
