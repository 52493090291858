import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';

import { type FC, useMemo } from 'react';

import type { RNEA } from '@feip-internal/fp-ts';
import { F, O, RA, S } from '@feip-internal/fp-ts';

// import * as O from 'fp-ts/Option';
// import * as RA from 'fp-ts/ReadonlyArray';
// import type * as RNEA from 'fp-ts/ReadonlyNonEmptyArray';
// import * as S from 'fp-ts/string';
// import { flow, pipe } from 'fp-ts/function';
import styles from './StyledMultiselect.module.scss';

import { CheckCircleIcon, ChevronBottomSmallIcon } from '@assets/icons';

export type StyledMultiselectProps<T> = Readonly<{
    options: RNEA.ReadonlyNonEmptyArray<T>;
    placeholder: string;
    onOptionSelect: (option: T) => void;
    getOptionKey: (option: T) => string | number;
    getOptionSelectionState: (option: T) => boolean;
    getOptionLabel: (option: T) => string;
    className?: string;
    handleApplyingFilters?: () => void;
}>;

export const StyledMultiselect = <T,>(props: StyledMultiselectProps<T>): ReturnType<FC> => {
    const {
        options,
        placeholder,
        onOptionSelect,
        getOptionKey,
        getOptionSelectionState,
        getOptionLabel,
        className,
        handleApplyingFilters,
    } = props;

    const label = useMemo(
        () =>
            F.pipe(
                options,
                RA.filterMap(
                    F.flow(O.fromPredicate(getOptionSelectionState), O.map(getOptionLabel)),
                ),
                RA.intercalate(S.Monoid)(', '),
            ),
        [getOptionLabel, getOptionSelectionState, options],
    );
    const hasLabel = S.size(label) !== 0;

    const handleSelect = (option: T) => (event: Event) => {
        event.preventDefault();
        onOptionSelect(option);
    };

    const handleOpenChange = (open: boolean) => {
        if (!open) handleApplyingFilters?.();
    };

    return (
        <RadixDropdownMenu.Root onOpenChange={handleOpenChange}>
            <RadixDropdownMenu.Trigger className={clsx(styles.trigger, className)}>
                <span className={styles.selectedValue}>{hasLabel ? label : placeholder}</span>
                <ChevronBottomSmallIcon className={styles.chevron} />
            </RadixDropdownMenu.Trigger>
            <RadixDropdownMenu.Portal>
                <RadixDropdownMenu.Content align="start" side="bottom" className={styles.list}>
                    {options.map((option) => (
                        <RadixDropdownMenu.Item
                            key={getOptionKey(option)}
                            onSelect={handleSelect(option)}
                            className={styles.listItem}
                        >
                            {getOptionLabel(option)}
                            <CheckCircleIcon
                                className={clsx(
                                    styles.itemIndicator,
                                    getOptionSelectionState(option) && styles.isVisible,
                                )}
                            />
                        </RadixDropdownMenu.Item>
                    ))}
                </RadixDropdownMenu.Content>
            </RadixDropdownMenu.Portal>
        </RadixDropdownMenu.Root>
    );
};
