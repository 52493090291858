import { spread } from 'patronum';

import type { Domain, Effect, EventCallable, Store } from 'effector';
import { attach, combine, createEvent, sample } from 'effector';

import { F } from '@feip-internal/fp-ts';

import {
    createFloatRangeFilter,
    createIntegerRangeFilter,
    createMultiselectFilter,
} from '@stores/filters/factory';
import type {
    FloatRangeFilterModel,
    IntegerRangeFilterModel,
    MultiselectFilterModel,
} from '@stores/filters/lib';

import type { LocalityEnum, RoomsFilterEnum, SearchFilters } from '@api/generated';

export type SearchFiltersModel = Readonly<{
    roomCountFilterModel: MultiselectFilterModel<RoomsFilterEnum>;
    advantagesFilterModel: MultiselectFilterModel<string>;
    localityFilterModel: MultiselectFilterModel<LocalityEnum>;
    projectFilterModel: MultiselectFilterModel<string>;
    completionYearFilterModel: MultiselectFilterModel<string>;
    areaRangeFilterModel: FloatRangeFilterModel;
    priceRangeFilterModel: IntegerRangeFilterModel;
    applyFx: Effect<void, void>;
    resetFx: Effect<void, boolean>;
    clear: EventCallable<void>;
    initialize: EventCallable<SearchFilters>;
    $searchFiltersState: Store<SearchFilters>;
}>;

export const createSearchFiltersModel = (
    domain: Domain,
    applyFx: Effect<SearchFilters, void>,
    resetFx: Effect<void, boolean>,
): SearchFiltersModel => {
    const initialize = createEvent<SearchFilters>({ domain });
    const clear = createEvent({ domain });

    const roomCountFilterModel = createMultiselectFilter<RoomsFilterEnum>(domain);
    const advantagesFilterModel = createMultiselectFilter<string>(domain);
    const localityFilterModel = createMultiselectFilter<LocalityEnum>(domain);
    const projectFilterModel = createMultiselectFilter<string>(domain);
    const completionYearFilterModel = createMultiselectFilter<string>(domain);
    const areaRangeFilterModel = createFloatRangeFilter(domain);
    const priceRangeFilterModel = createIntegerRangeFilter(domain);

    const $searchFiltersState: Store<SearchFilters> = combine({
        roomsNumber: roomCountFilterModel.$state,
        advantages: advantagesFilterModel.$state,
        locality: localityFilterModel.$state,
        project: projectFilterModel.$state,
        completionYear: completionYearFilterModel.$state,
        area: areaRangeFilterModel.$parsedState,
        price: priceRangeFilterModel.$parsedState,
    });

    const internalApplyFx = attach({
        source: $searchFiltersState,
        effect: applyFx,
        mapParams: (_params: void, state: SearchFilters) => state,
    });

    spread({
        source: initialize,
        targets: {
            roomsNumber: roomCountFilterModel.initialize,
            advantages: advantagesFilterModel.initialize,
            locality: localityFilterModel.initialize,
            project: projectFilterModel.initialize,
            completionYear: completionYearFilterModel.initialize,
            area: areaRangeFilterModel.initialize,
            price: priceRangeFilterModel.initialize,
        },
    });

    sample({
        clock: resetFx.doneData,
        filter: F.identity<boolean>,
        target: [
            roomCountFilterModel.reset,
            advantagesFilterModel.reset,
            localityFilterModel.reset,
            projectFilterModel.reset,
            completionYearFilterModel.reset,
            areaRangeFilterModel.reset,
            priceRangeFilterModel.reset,
        ],
    });

    return {
        roomCountFilterModel,
        advantagesFilterModel,
        localityFilterModel,
        projectFilterModel,
        completionYearFilterModel,
        areaRangeFilterModel,
        priceRangeFilterModel,
        applyFx: internalApplyFx,
        resetFx,
        clear,
        initialize,
        $searchFiltersState,
    };
};
