import { RoomsNumberEnum } from '@api/generated';

export const roomsQuantityDisplay = (quantity: RoomsNumberEnum) => {
    switch (quantity) {
        case RoomsNumberEnum.Studio:
            return 'Студия';
        case RoomsNumberEnum.One:
            return 'Однокомнатная';
        case RoomsNumberEnum.Two:
            return 'Двухкомнатная';
        case RoomsNumberEnum.Three:
            return 'Трёхкомнатная';
        case RoomsNumberEnum.Four:
            return 'Четырёхкомнатная';
        case RoomsNumberEnum.Five:
            return 'Пятикомнатная';
        case RoomsNumberEnum.Six:
            return 'Шестикомнатная';
        case RoomsNumberEnum.Seven:
            return 'Семикомнатная';
        default:
            return '';
    }
};

export const roomsQuantityNumericalDisplay = (quantity: RoomsNumberEnum) => {
    switch (quantity) {
        case RoomsNumberEnum.Studio:
            return 'Студия';
        case RoomsNumberEnum.One:
            return '1';
        case RoomsNumberEnum.Two:
            return '2';
        case RoomsNumberEnum.Three:
            return '3';
        case RoomsNumberEnum.Four:
            return '4';
        case RoomsNumberEnum.Five:
            return '5';
        case RoomsNumberEnum.Six:
            return '6';
        case RoomsNumberEnum.Seven:
            return '7';
        default:
            return '';
    }
};

export const meter = 'м';
export const squareMeter = `${meter}²`;

export const displaySquareMeters = (value?: number) =>
    value === undefined ? squareMeter : `${value} ${squareMeter}`;

export const displayMeters = (value?: number) =>
    value === undefined ? meter : `${value} ${meter}`;

const intlLocales: Intl.LocalesArgument = 'ru-RU';

const intlRublesFormatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
};

const rublesFormat = new Intl.NumberFormat(intlLocales, intlRublesFormatOptions);

export const displayRubles = (rubles: number) => rublesFormat.format(rubles);

const rublesCompactFormat = new Intl.NumberFormat(intlLocales, {
    ...intlRublesFormatOptions,
    notation: 'compact',
});

export const displayRublesCompact = (rubles: number) => rublesCompactFormat.format(rubles);
