import type { LocalityEnum, RoomsFilterEnum, SearchListParams } from '@api/generated';

export enum SearchSortMode {
    Price = 'price',
    Area = 'area',
}

export type SearchSortDirection = SearchListParams['sortDirection'];

export enum SearchSortOrder {
    PriceAsc = 'priceAsc',
    PriceDesc = 'priceDesc',
    AreaAsc = 'areaAsc',
    AreaDesc = 'areaDesc',
}

// export type SearchFiltersPayload = SearchListParams;
export type SearchFiltersPayload = Readonly<{
    priceRangeMin?: number;
    priceRangeMax?: number;
    areaRangeMin?: number;
    areaRangeMax?: number;
    roomsNumber?: readonly RoomsFilterEnum[];
    locality?: readonly LocalityEnum[];
    project?: readonly string[];
    completionYear?: readonly string[];
    advantages?: readonly string[];
}>;

export type SearchSortPayload = Readonly<{
    sortDirection: SearchSortDirection;
    sortMode: SearchSortMode;
}>;
