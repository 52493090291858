import type { FC } from 'react';

import { useUnit } from 'effector-react';

import type { MultiselectFilterModel } from '@stores/filters/lib';

import { FilterChip } from '@components/common/FilterChip/FilterChip';

import styles from './FiltersForm.module.scss';

export type FiltersFormThirdRowProps = Readonly<{
    advantagesFilterModel: MultiselectFilterModel<string>;
    className?: string;
    handleApplyingFilters?: () => void;
}>;

export const FiltersFormThirdRow: FC<FiltersFormThirdRowProps> = (props) => {
    const { advantagesFilterModel, handleApplyingFilters } = props;

    const [advantages, setOptionSelected] = useUnit([
        advantagesFilterModel.$state,
        advantagesFilterModel.setOptionSelected,
    ]);

    return (
        <fieldset className={styles.thirdRow}>
            {advantages.map(({ name, value, isSelected }) => (
                <FilterChip
                    key={value}
                    label={name}
                    size="small"
                    isChecked={isSelected}
                    onChange={(newIsSelected) => {
                        setOptionSelected({ value, isSelected: newIsSelected });
                        handleApplyingFilters?.();
                    }}
                />
            ))}
        </fieldset>
    );
};
