import type { FC } from 'react';

import { useUnit } from 'effector-react';

import type {
    FloatRangeFilterModel,
    IntegerRangeFilterModel,
    MultiselectFilterModel,
} from '@stores/filters/lib';

import { RoomsFilterEnum } from '@api/generated';

import { FilterChip } from '@components/common/FilterChip/FilterChip';
import styles from '@components/common/FiltersForm/FiltersForm.module.scss';
import { FloatRangeFilter } from '@components/common/RangeFilter/FloatRangeFilter';
import { IntegerRangeFilter } from '@components/common/RangeFilter/IntegerRangeFilter';

export type FiltersFormFirstRowProps = Readonly<{
    roomsCountFilterModel: MultiselectFilterModel<RoomsFilterEnum>;
    areaFilterModel: FloatRangeFilterModel;
    priceFilterModel: IntegerRangeFilterModel;
    className?: string;
    handleApplyingFilters?: () => void;
}>;

export const FiltersFormFirstRow: FC<FiltersFormFirstRowProps> = (props) => {
    const {
        roomsCountFilterModel,
        areaFilterModel,
        priceFilterModel,
        className,
        handleApplyingFilters,
    } = props;

    const [roomsCountFilter, setOptionSelected] = useUnit([
        roomsCountFilterModel.$state,
        roomsCountFilterModel.setOptionSelected,
    ]);

    return (
        <div className={className}>
            <fieldset className={styles.roomsQuantity}>
                {roomsCountFilter.map(({ value, name, isSelected }) => (
                    <FilterChip
                        key={value}
                        size="medium"
                        label={name}
                        isChecked={isSelected}
                        onChange={(newIsSelected) => {
                            setOptionSelected({ value, isSelected: newIsSelected });
                            handleApplyingFilters?.();
                        }}
                        hasFixedWidth={value !== RoomsFilterEnum.Studio}
                    />
                ))}
            </fieldset>

            <FloatRangeFilter
                label="Площадь (м²)"
                model={areaFilterModel}
                handleApplyingFilters={handleApplyingFilters}
            />

            <IntegerRangeFilter
                label="Стоимость (₽)"
                model={priceFilterModel}
                handleApplyingFilters={handleApplyingFilters}
            />
        </div>
    );
};
