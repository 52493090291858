import { type FC } from 'react';

import { useUnit } from 'effector-react';

import { RA } from '@feip-internal/fp-ts';

import { type MultiselectFilterModel } from '@stores/filters/lib';

import type { LocalityEnum } from '@api/generated';

import styles from './FiltersForm.module.scss';

import { StyledMultiselect } from '../StyledMultiselect/StyledMultiselect';

export type FiltersFormSecondRowProps = Readonly<{
    localityFilterModel: MultiselectFilterModel<LocalityEnum>;
    projectsFilterModel: MultiselectFilterModel<string>;
    completionYearFilterModel: MultiselectFilterModel<string>;
    className?: string;
    handleApplyingFilters?: () => void;
}>;

export const FiltersFormSecondRow: FC<FiltersFormSecondRowProps> = (props) => {
    const {
        localityFilterModel,
        projectsFilterModel,
        completionYearFilterModel,
        className,
        handleApplyingFilters,
    } = props;

    const [
        localities,
        projects,
        completionYears,
        setLocalityOptionEnabled,
        setProjectOptionEnabled,
        setCompletionYearOptionEnabled,
    ] = useUnit([
        localityFilterModel.$state,
        projectsFilterModel.$state,
        completionYearFilterModel.$state,
        localityFilterModel.setOptionSelected,
        projectsFilterModel.setOptionSelected,
        completionYearFilterModel.setOptionSelected,
    ]);

    return (
        <fieldset className={className}>
            {RA.isNonEmpty(localities) && (
                <StyledMultiselect
                    options={localities}
                    placeholder="Район"
                    onOptionSelect={({ value, isSelected }) => {
                        setLocalityOptionEnabled({ value, isSelected: !isSelected });
                    }}
                    getOptionKey={(option) => option.value}
                    getOptionSelectionState={({ isSelected }) => isSelected}
                    getOptionLabel={({ name }) => name}
                    className={styles.multiselect}
                    handleApplyingFilters={handleApplyingFilters}
                />
            )}
            {RA.isNonEmpty(projects) && (
                <StyledMultiselect
                    options={projects}
                    placeholder="Жилой комплекс"
                    onOptionSelect={({ value, isSelected }) => {
                        setProjectOptionEnabled({ value, isSelected: !isSelected });
                    }}
                    getOptionKey={(option) => option.value}
                    getOptionSelectionState={({ isSelected }) => isSelected}
                    getOptionLabel={({ name }) => name}
                    className={styles.multiselect}
                    handleApplyingFilters={handleApplyingFilters}
                />
            )}
            {RA.isNonEmpty(completionYears) && (
                <StyledMultiselect
                    options={completionYears}
                    placeholder="Сдача"
                    onOptionSelect={({ value, isSelected }) => {
                        setCompletionYearOptionEnabled({ value, isSelected: !isSelected });
                    }}
                    getOptionKey={(option) => option.value}
                    getOptionSelectionState={({ isSelected }) => isSelected}
                    getOptionLabel={({ name }) => name}
                    className={styles.multiselect}
                    handleApplyingFilters={handleApplyingFilters}
                />
            )}
        </fieldset>
    );
};
