import Router from 'next/router';
import { spread } from 'patronum';

import { attach, createEffect, createEvent, createStore, merge, sample } from 'effector';
import { createGate } from 'effector-react';

import { F, Predicate } from '@feip-internal/fp-ts';

import { SearchSortOrder } from '@domains/search';

import type { ApartmentPreview, PaginatedList, SearchFilters } from '@api/generated';
import { apiGetSearchResults } from '@api/methods';

import { makeSearchRoute } from '@utils/route';
import {
    makeSearchPayloadFromState,
    serializeSearchFiltersToQuery,
    serializeSortOrderToQuery,
} from '@utils/search';

import { searchDomain } from './domain';
import { createSearchFiltersModel } from './factory';
import { type FetchMoreSearchResultsFxPayload, type FetchMoreSearchResultsFxResponse } from './lib';
import { fetchSearchResultsFx } from './server-side';

// --- Gates ---

export const SearchGate = createGate<void>({ domain: searchDomain });

// --- Units ---

export const $searchSortOrder = createStore<SearchSortOrder>(SearchSortOrder.PriceAsc, {
    domain: searchDomain,
});

export const $searchItemList = createStore<readonly ApartmentPreview[]>([], {
    domain: searchDomain,
});

export const $searchItemListPagination = createStore<PaginatedList>(
    { page: 0, page_size: 0, total: 0 },
    { domain: searchDomain },
);

export const $isSearchDrawerOpen = createStore<boolean>(false, { domain: searchDomain });

const applyFiltersFx = attach({
    source: $searchSortOrder,
    effect: async (sortOrder, payload: SearchFilters) => {
        await Router.push({
            pathname: makeSearchRoute(),
            query: {
                ...serializeSearchFiltersToQuery(payload),
                ...serializeSortOrderToQuery(sortOrder),
            },
        });
    },
});

const resetFiltersFx = createEffect<void, boolean>({
    domain: searchDomain,
    handler: async () => {
        await Router.push(makeSearchRoute());
        return false;
    },
});

export const setSearchDrawerOpen = createEvent<boolean>({ domain: searchDomain });

export const searchFilters = createSearchFiltersModel(searchDomain, applyFiltersFx, resetFiltersFx);

export const changeSortOrderFx = attach({
    source: searchFilters.$searchFiltersState,
    effect: async (filters, sortOrder: SearchSortOrder) => {
        await Router.push({
            pathname: makeSearchRoute(),
            query: {
                ...serializeSearchFiltersToQuery(filters),
                ...serializeSortOrderToQuery(sortOrder),
            },
        });
    },
});

export const fetchMoreSearchResultsFx = attach({
    source: [
        $searchSortOrder,
        searchFilters.$searchFiltersState,
        $searchItemList,
        $searchItemListPagination,
    ],
    effect: async (
        [sortOrder, filtersState, itemList, itemListPagination],
        _payload: FetchMoreSearchResultsFxPayload,
    ): Promise<FetchMoreSearchResultsFxResponse> => {
        const { filters, items, page, page_size, total } = await apiGetSearchResults(
            makeSearchPayloadFromState(
                sortOrder,
                filtersState,
                itemListPagination.page_size,
                itemListPagination.page + 1,
            ),
        );

        return {
            filters,
            list: [...itemList, ...items],
            listPagination: { page, page_size, total },
            sortOrder,
        };
    },
});

spread({
    source: merge([fetchSearchResultsFx.doneData, fetchMoreSearchResultsFx.doneData]),
    targets: {
        sortOrder: $searchSortOrder,
        list: $searchItemList,
        listPagination: $searchItemListPagination,
        filters: searchFilters.initialize,
    },
});

sample({
    clock: SearchGate.status,
    filter: Predicate.not(F.identity<boolean>),
    target: searchFilters.clear,
});

sample({ clock: setSearchDrawerOpen, target: $isSearchDrawerOpen });
