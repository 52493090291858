import type { IncomingHttpHeaders } from 'http';
import type { ParsedUrlQuery } from 'querystring';

import type { SearchSortOrder } from '@domains/search';

import type { ApartmentPreview, PaginatedList, SearchFilters } from '@api/generated';

export type FetchSearchResultsFxPayload = Readonly<{
    headers: IncomingHttpHeaders;
    query: ParsedUrlQuery;
}>;

export type FetchSearchResultsFxResponse = Readonly<{
    filters: SearchFilters;
    sortOrder: SearchSortOrder;
    list: readonly ApartmentPreview[];
    listPagination: PaginatedList;
}>;

export type FetchMoreSearchResultsFxPayload = void;

export type FetchMoreSearchResultsFxResponse = Readonly<{
    filters: SearchFilters;
    sortOrder: SearchSortOrder;
    list: readonly ApartmentPreview[];
    listPagination: PaginatedList;
}>;

export type ResetFxPayload = Readonly<{ shouldLoadSearchPage: boolean }>;

export const searchPageSize = 24;
